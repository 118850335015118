import ReactPlayer from "react-player";
import { getImageUrl } from "../helpers/utils";

const Services = ({ data }) => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4">
          {data &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="service-item position-relative h-100"
                  >
                    {item.catalogContentUrl.includes("mp4") ? (
                      <ReactPlayer
                        className="img-fluid"
                        controls
                        playing
                        volume={"0"}
                        url={getImageUrl(item.catalogContentUrl)}
                      />
                    ) : (
                      <img
                        className="img-fluid"
                        src={getImageUrl(item.catalogContentUrl)}
                        alt={item.catalogContentId}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Services;
