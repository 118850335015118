import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-4">Ofis</h4>
            {/* <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>Karaçayır Mah.
              Eflatun Sok ide işmerkezi kat:4 no:24 Merkez /BOLU
            </p> */}
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+90 532 344 69 47
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>parsformengrup@gmail.com
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-4">Hızlı Linkler</h4>
            <Link to={"/about"} className="btn btn-link">
              Hakkımızda
            </Link>
            <Link to={"/contact"} className="btn btn-link">
              İletişim
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-4">Çalışma Saatleri</h4>
            <p className="mb-1">Pazartesi - Cuma</p>
            <h6 className="text-light">09:00 - 19:00</h6>
            <p className="mb-1">Cumartesi</p>
            <h6 className="text-light">11:00 - 19:00</h6>
            <p className="mb-1">Sunday</p>
            <h6 className="text-light">Kapalı</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
