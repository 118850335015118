import EmakContact from "../components/EmakContact";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import TopBar from "../components/TopBar";

const Contact = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <EmakContact />
      <Footer />
    </div>
  );
};
export default Contact;
