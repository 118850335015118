import { Link } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
const TopBar = () => {
  return (
    <div className="container-fluid bg-primary text-white d-none d-lg-flex">
      <div className="container py-3">
        <div className="d-flex align-items-center">
          <Link to={"/"}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                  src="/img/logo.jpg"
                  alt="Image"
                />
              </div>
              <div>
                <h4
                  className="text-white fw-bold m-0"
                  style={{ fontFamily: "posterma" }}
                >
                  PARS TURKEY
                </h4>
              </div>
            </div>
          </Link>
          <div className="ms-auto d-flex align-items-center">
            {/* <small className="ms-4">
              <a
                href="https://goo.gl/maps/eaCoes8hzfuYnRMGA"
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer", color: "white" }}
              >
                <i className="fa fa-map-marker-alt me-3"></i>Karaçayır Mah.
                Eflatun Sok ide işmerkezi kat:4 no:24 Merkez /BOLU
              </a>
            </small> */}
            <small className="ms-4">
              <a
                href="mailto:parsformengrup@gmail.com"
                style={{ cursor: "pointer", color: "white" }}
              >
                <i className="fa fa-envelope me-3"></i>parsformengrup@gmail.com
              </a>
            </small>
            <small className="ms-4">
              <a
                href="tel:+90 532 344 69 47"
                style={{ cursor: "pointer", color: "white" }}
              >
                <i className="fa fa-phone-alt me-3"></i>+90 532 344 69 47
              </a>
            </small>
            <div className="ms-3 d-flex">
              {/* <a
                className="btn btn-sm-square btn-light text-primary rounded-circle ms-2"
                href="https://www.facebook.com/emakyapicelik"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-sm-square btn-light text-primary rounded-circle ms-2"
                href="https://www.instagram.com/emakyapicelik/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a> */}
              <a
                className="btn btn-sm-square btn-light text-primary rounded-circle ms-2"
                href="https://api.whatsapp.com/send/?phone=905323446947&text=Merhaba&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopBar;
