import moment from "moment/moment";
import { useState } from "react";
import { sendMessage } from "../services/messages";
import EmakLoader from "./EmakLoader";

/* eslint-disable jsx-a11y/iframe-has-title */
const EmakContact = () => {
  const [message, setMessage] = useState({
    name: "",
    surname: "",
    email: "",
    subject: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <EmakLoader active={loading} />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Bizimle İletişime Geçin
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "800px" }}
          >
            <p className="fs-5 fw-medium text-primary">İletişime Geçin</p>
            <h1 className="display-5 mb-5">
              Herhangi bir sorunuz varsa,lütfen bizimle iletişime geçin
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h3 className="mb-4">Herhangi bir sorunuz mu var ?</h3>
              <p className="mb-4">Lütfen Formu Doldurun</p>

              <div className="row g-3">
                <div className="col-md-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ad"
                      onChange={(e) => {
                        setMessage({ ...message, name: e.target.value });
                      }}
                    />
                    <label for="name">Ad</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="surname"
                      placeholder="Ad"
                      onChange={(e) => {
                        setMessage({ ...message, surname: e.target.value });
                      }}
                    />
                    <label for="surname">Soyad</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="E-Mail"
                      onChange={(e) => {
                        setMessage({ ...message, email: e.target.value });
                      }}
                    />
                    <label for="email">E-Mail</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Konu"
                      onChange={(e) => {
                        setMessage({ ...message, subject: e.target.value });
                      }}
                    />
                    <label for="subject">Konu</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Mesaj"
                      id="message"
                      style={{ height: "200px" }}
                      onChange={(e) => {
                        setMessage({ ...message, description: e.target.value });
                      }}
                    ></textarea>
                    <label for="message">Mesaj</label>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary rounded-pill py-3 px-5"
                    onClick={async (e) => {
                      if (
                        message.name.trim() === "" ||
                        message.email.trim() === "" ||
                        message.subject.trim() === "" ||
                        message.description.trim() === ""
                      ) {
                        alert("alanları doldurunuz");
                        return;
                      }
                      setLoading(true);
                      const payload = {
                        ...message,
                        date: moment().format(),
                      };
                      await sendMessage(payload);
                      setLoading(false);
                    }}
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <h3 className="mb-4">İletişim Detayı</h3>
              {/* <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://goo.gl/maps/eaCoes8hzfuYnRMGA",
                    "_blank"
                  );
                }}
                className="d-flex border-bottom pb-3 mb-3"
              >
                <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                  <i className="fa fa-map-marker-alt text-white"></i>
                </div>
                <div className="ms-3">
                  <h6>Ofis</h6>
                  <span>
                    Karaçayır Mah. Eflatun Sok ide işmerkezi kat:4 no:24 Merkez
                    /BOLU
                  </span>
                </div>
              </div> */}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("tel:+90 532 344 69 47", "");
                }}
                className="d-flex border-bottom pb-3 mb-3"
              >
                <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ms-3">
                  <h6>Cep</h6>
                  <span>+90 532 344 69 47</span>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("mailto:parsformengrup@gmail.com", "_blank");
                }}
                className="d-flex border-bottom-0 pb-3 mb-3"
              >
                <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                  <i className="fa fa-envelope text-white"></i>
                </div>
                <div className="ms-3">
                  <h6>Email</h6>
                  <span>parsformengrup@gmail.com</span>
                </div>
              </div>

              {/* <iframe
                className="w-100 rounded"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1511.7390552616694!2d31.604952!3d40.729504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409d3fbae4218a77%3A0xb6e0c6803b75e91b!2sYata%C5%9F%20Bedding%20Bolu!5e0!3m2!1sen!2str!4v1680011379926!5m2!1sen!2str"
                frameborder="0"
                style={{ minHeight: "300px", border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmakContact;
