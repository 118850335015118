const EmakAbout = () => {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 text-primary">HAKKIMIZDA</h1>
              <p>
                PARSTURKEY olarak Türkiyenin 81 iline 2005 yılından beri
                ücretsiz kurulum yapmaktayız her zaman Güçlü teknik kadromuz ile
                yenilikçi, dinamik, verimli, güvenilir bir takım ruhu anlayışı
                ile kalite ve müşteri memnuniyeti ilkelerinden taviz vermeden
                optimal fiyatlarla hizmet sunmaktayız.
              </p>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <div>
                  <a
                    className="d-inline-flex align-items-center rounded overflow-hidden border border-primary"
                    href="tel:+90 532 344 69 47"
                  >
                    <span
                      className="btn-lg-square bg-primary"
                      style={{ width: "55px", height: "55px" }}
                    >
                      <i className="fa fa-phone-alt text-white"></i>
                    </span>
                    <span className="fs-5 fw-medium mx-4">
                      +90 532 344 69 47
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    className="d-inline-flex align-items-center rounded overflow-hidden border border-primary"
                    href="https://api.whatsapp.com/send/?phone=905323446947&text=Merhaba&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="btn-lg-square bg-primary"
                      style={{ width: "55px", height: "55px" }}
                    >
                      <i
                        className="fab fa-whatsapp"
                        style={{ color: "white", fontSize: "25px" }}
                      ></i>
                    </span>
                    <span className="fs-5 fw-medium mx-4">
                      +90 532 344 69 47
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 text-primary">FİRMA POLİTİKAMIZ</h1>
              <p>
                PARSTURKEY müşteri beklentilerini her defasında zamanında ve tam
                olarak karşılamak, çevreye saygılı, estetik ve fonksiyonel
                açıdan müşteri memnuniyetini en üst düzeyde sağlayacak hizmeti
                sunmaktır. Hizmette güven, müşteri memnuniyeti, kalite, hız ve
                stabilite prensiplerine bağlı, etik değerleri olan, müşteri
                odaklı çözümlere önem veren, sosyal sorumluluk sahibi bir firma
                olmak ve %100 müşteri memnuniyeti sağlamaktır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmakAbout;
