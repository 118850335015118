import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmakLoader from "../components/EmakLoader";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import Services from "../components/Services";
import TopBar from "../components/TopBar";
import { getCatalogById, getCatalogImageById } from "../services/Catalog";

const Projects = () => {
  const { id } = useParams();
  const [sector, setSector] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getCatalogImageById(id);
      const catalogRes = await getCatalogById(id);
      setSector(catalogRes.data[0]);

      setImages(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <div>
      <EmakLoader active={loading} />
      <TopBar />
      <NavBar />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            {sector.catalogName}
          </h1>
        </div>
      </div>
      <Services data={images} />
      <Footer />
    </div>
  );
};
export default Projects;
