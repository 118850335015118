import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AUTH_STORAGE } from "../const/api";
import useLocalStorage from "../hooks/useLocalStorage";

export default function PrivateRoute({ component: Component, ...rest }) {
    const [auth] = useLocalStorage(AUTH_STORAGE);

    return (
        <Route

            {...rest}
            render={({ location, ...props }) => {
                if (!auth) {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: location } }} />
                    );
                }
                return <Component {...props} />;
            }}
        />
    );
}

