import axios from "axios";
import { API_URL } from "../const/api";

export const defaultAxiosInstance = axios.create({
    baseURL: API_URL,
});


defaultAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.location.href = "/login";
            localStorage.clear();
        }
    }
);
