import NavBar from "../components/Navbar";
import TopBar from "../components/TopBar";
import EmakAbout from "../components/EmakAbout";
import Footer from "../components/Footer";
const About = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h3 className="display-2 text-white mb-4 animated slideInDown">
            Hakkımızda
          </h3>
        </div>
      </div>
      <EmakAbout />
      <Footer />
    </div>
  );
};
export default About;
