/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Coursel from "../components/Coursel";
import EmakAbout from "../components/EmakAbout";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import TopBar from "../components/TopBar";

const Home = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <Coursel />
      <EmakAbout />
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <h1 className="display-5 mb-5">SEKTÖRLERİMİZ</h1>
          </div>
          <div className="row g-4">
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
                marginBottom: "50px",
              }}
            >
              <div
                class="service-item position-relative h-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  class="img-fluid"
                  src="https://parsturkey.com.tr/images/16961eb4-cd06-42ad-8917-dc5ba36a0c46construction-site-silhouettes.jpg"
                  alt="37"
                />
              </div>
              <h3 style={{ textAlign: "center" }}>İNŞAAT</h3>
            </div>
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
                marginBottom: "50px",
              }}
            >
              <div
                class="service-item position-relative h-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  class="img-fluid"
                  src="https://parsturkey.com.tr/images/c8ba413d-19fb-4dd1-9de0-78aaa7589904corporate-business-handshake-business-partners.jpg"
                  alt="38"
                />
              </div>
              <h3 style={{ textAlign: "center" }}>EMLAK</h3>
            </div>
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
                marginBottom: "50px",
              }}
            >
              <div
                class="service-item position-relative h-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  class="img-fluid"
                  src="https://i0.wp.com/adviceal.com/teknoloji/wp-content/uploads/2019/09/code.jpg?fit=2000%2C1335&ssl=1"
                  alt="39"
                />
              </div>
              <h3 style={{ textAlign: "center" }}>YAZILIM</h3>
            </div>
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
                marginBottom: "50px",
              }}
            >
              <div
                class="service-item position-relative h-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  class="img-fluid"
                  src="https://parsturkey.com.tr/images/0fd4439d-3e62-4999-90bd-7937e307ca04WhatsAppImage2023-05-16at23.47.28.jpeg"
                  alt="40"
                />
              </div>
              <h3 style={{ textAlign: "center" }}>
                YAT MOBİLYA DEKORASYON VE BAKIM
              </h3>
            </div>
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
                marginBottom: "50px",
              }}
            >
              <div
                class="service-item position-relative h-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  class="img-fluid"
                  src="https://parsturkey.com.tr/images/477ef966-a232-4b5f-8598-6d9ba2cb1f71WhatsAppImage2023-05-16at23.47.26.jpeg"
                  alt="41"
                />
              </div>
              <h3 style={{ textAlign: "center" }}>OFİS MOBİLYALARI</h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
