import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import About from "../public-pages/About";
import Contact from "../public-pages/Contact";
import Home from "../public-pages/Home";
import Projects from "../public-pages/Projects";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../public-pages/Login";
import PrivateHome from "../private-pages/PrivateHome";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" component={PrivateHome} />
        <PublicRoute exact path="/Login" component={Login} />
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/Home" component={Home} />
        <PublicRoute exact path="/About" component={About} />
        <PublicRoute exact path="/Contact" component={Contact} />
        <PublicRoute exact path="/projects/:id" component={Projects} />
        <PublicRoute exact path="/*" component={Home} />
      </Switch>
    </Router>
  );
}
