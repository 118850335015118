import { useState } from "react";
import { auth } from "../services/auth";
import useLocalStorage from "../hooks/useLocalStorage";
import { AUTH_STORAGE } from "../const/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Login = () => {
  const [authStorage, setAuthStorage] = useLocalStorage(AUTH_STORAGE);
  const history = useHistory();
  const [loginPayload, setLoginPayload] = useState({
    username: "",
    password: "",
  });

  return (
    <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i
                          className="fas fa-cubes fa-2x me-3"
                          style={{ color: "#ff6219" }}
                        />
                        <span className="h1 fw-bold mb-0">PARS TURKEY</span>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="form2Example17"
                          className="form-control form-control-lg"
                          value={loginPayload.username}
                          onChange={(e) => {
                            setLoginPayload({
                              ...loginPayload,
                              username: e.target.value,
                            });
                          }}
                        />
                        <label className="form-label" htmlFor="form2Example17">
                          Kullanıcı Adı
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form2Example27"
                          className="form-control form-control-lg"
                          value={loginPayload.password}
                          onChange={(e) => {
                            setLoginPayload({
                              ...loginPayload,
                              password: e.target.value,
                            });
                          }}
                        />
                        <label className="form-label" htmlFor="form2Example27">
                          Şifre
                        </label>
                      </div>
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="button"
                          onClick={async () => {
                            const authRes = await auth(
                              loginPayload.username,
                              loginPayload.password
                            );
                            setAuthStorage(authRes.data);
                            history.push("/dashboard");
                          }}
                        >
                          Giriş
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
