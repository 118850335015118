import { defaultAxiosInstance } from "./api";

export const getActiveCatalogs = async () => {
  const res = await defaultAxiosInstance.get("/Catalog/GetActiveCatalogs");
  return res;
};

export const getAllCatalogs = async (username, password) => {
  const res = await defaultAxiosInstance.get(
    `/Catalog/GetAllCatalogs/Username/${username}/Password/${password}`
  );
  return res;
};

export const addCatalog = async (username, password, payload) => {
  const res = await defaultAxiosInstance.post(
    `/Catalog/AddCatalog/Username/${username}/Password/${password}`,
    payload
  );
  return res;
};

export const getCatalogImageById = async (id) => {
  const res = await defaultAxiosInstance.get(
    `/CatalogContent/GetCatalogImageById/CatalogId/${id}`
  );
  return res;
};

export const getCatalogById = async (id) => {
  const res = await defaultAxiosInstance.get(
    `/Catalog/GetCatalogById/CatalogId/${id}`
  );
  return res;
};

export const hideCatalog = async (username, password, id) => {
  const res = await defaultAxiosInstance.get(
    `/Catalog/HideCatalog/Username/${username}/Password/${password}/CatalogId/${id}`
  );
  return res;
};

export const showCatalog = async (username, password, id) => {
  const res = await defaultAxiosInstance.get(
    `/Catalog/ShowCatalog/Username/${username}/Password/${password}/CatalogId/${id}`
  );
  return res;
};
