import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
 
export default function EmakLoader({ active, children }) {
  return (
    <LoadingOverlay
    styles={{
        overlay: (base) => ({
          ...base,
          background: 'black',
          position:'fixed',
          opacity:'0.5',
          zIndex:99999
        })
      }}
      active={active}
      spinner={<BounceLoader color='#107759' />}
    >
      {children}
    </LoadingOverlay>
  )
}