/* eslint-disable jsx-a11y/img-redundant-alt */
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getActiveCatalogs } from "../services/Catalog";
import EmakLoader from "./EmakLoader";

/* eslint-disable jsx-a11y/anchor-is-valid */
const NavBar = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCatalogs = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getActiveCatalogs();
      let data = [];

      res.data.forEach((element) => {
        if (element.catalogId === 14) {
          data.push({ ...element, order: 1 });
        } else if (element.catalogId === 15) {
          data.push({ ...element, order: 2 });
        } else if (element.catalogId === 16) {
          data.push({ ...element, order: 3 });
        } else if (element.catalogId === 12) {
          data.push({ ...element, order: 4 });
        } else {
          data.push({ ...element, order: 5 });
        }
      });
      data.sort((a, b) => a.order - b.order);

      setCatalogs(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCatalogs();
  }, [getCatalogs]);

  return (
    <div className="container-fluid bg-white sticky-top">
      <EmakLoader active={loading} />
      <div className="container">
        <nav className="navbar navbar-expand-lg bg-white navbar-light p-lg-0">
          <Link to={"/"} className="navbar-brand d-lg-none">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                  src="/img/logo.jpg"
                  alt="Image"
                />
              </div>
              <div>
                <span
                  className="text-black fw-bold m-0"
                  style={{ fontFamily: "posterma" }}
                >
                  PARS TURKEY
                </span>
              </div>
            </div>
          </Link>
          <button
            type="button"
            className="navbar-toggler me-0"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <Link to="/home" className="nav-item nav-link">
                Anasayfa
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="/home"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Sektörlerimiz
                </Link>
                <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">
                  {catalogs.map((item, index) => {
                    if (item.isActive) {
                      return (
                        <Link
                          key={index}
                          to={`/projects/${item.catalogId}`}
                          className="dropdown-item"
                        >
                          {item.catalogName}
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
              <Link to="/about" className="nav-item nav-link">
                Hakkımızda
              </Link>
              <Link to="/contact" className="nav-item nav-link">
                İletişim
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
