import { useCallback, useEffect, useState } from "react";
import { AUTH_STORAGE } from "../const/api";
import useLocalStorage from "../hooks/useLocalStorage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { auth } from "../services/auth";
import {
  addCatalog,
  getAllCatalogs,
  hideCatalog,
  showCatalog,
} from "../services/Catalog";
import EmakLoader from "../components/EmakLoader";
import PrivateNavbar from "../components/PrivateNavbar";
import SectorTable from "../components/SectorTable";

const PrivateHome = () => {
  const [authStorage] = useLocalStorage(AUTH_STORAGE);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [payload, setPayload] = useState({
    CatalogName: "",
    isActive: true,
    file: null,
    CatalogDescription: null,
  });

  const prepareDataCallback = useCallback(async () => {
    if (authStorage) {
      setLoading(true);
      await auth(authStorage.username, authStorage.password);
      const catalogRes = await getAllCatalogs(
        authStorage.username,
        authStorage.password
      );

      setSectors(catalogRes.data);
      setLoading(false);
    } else {
      window.localStorage.clear();
      history.push("/login");
    }
  }, []);

  const getCatalogData = useCallback(async () => {
    setLoading(true);
    const catalogRes = await getAllCatalogs(
      authStorage.username,
      authStorage.password
    );

    setSectors(catalogRes.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (authStorage) {
      prepareDataCallback();
    }
  }, [authStorage]);

  return (
    <>
      {loading ? (
        <EmakLoader active={loading} />
      ) : (
        <div style={{ padding: "30px" }}>
          <SectorTable
            onCheck={async (item) => {
              setLoading(true);
              if (item.isActive) {
                await hideCatalog(
                  authStorage.username,
                  authStorage.password,
                  item.catalogId
                );
              } else {
                await showCatalog(
                  authStorage.username,
                  authStorage.password,
                  item.catalogId
                );
              }
              getCatalogData();
            }}
            onSectorContent={(item) => {
              history.push(`sector-content/${item.catalogId}`);
            }}
            data={sectors}
          />
        </div>
      )}
    </>
  );
};
export default PrivateHome;
