export default function SectorTable({ data, onCheck, onSectorContent }) {
  console.log(data);
  return (
    <>
      <table class="table table-dark" style={{ borderRadius: "20px" }}>
        <thead>
          <tr>
            <th scope="col" style={{ textAlign: "center" }}>
              Sektör
            </th>
            {/* <th scope="col">Sektör Açıklaması</th> */}
            {/* <th scope="col">Sektör Resmi</th> */}
            <th scope="col" style={{ textAlign: "center" }}>
              Aktif
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Sektör İçeriklerini Düzenle
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <th scope="row" style={{ textAlign: "center" }}>
                {item.catalogName}
              </th>
              {/* <td>{item.catalogDescription}</td> */}
              {/* <td>
                <img
                  style={{ maxWidth: "250px", width: "100%" }}
                  className="img-fluid"
                  src={"getImageUrl(item.catalogImageUrl)"}
                />
              </td> */}
              <td style={{ textAlign: "center" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={item.isActive}
                  onChange={() => onCheck(item)}
                />
              </td>
              <td style={{ textAlign: "center" }}>
                <button
                  onClick={() => onSectorContent(item)}
                  variant="contained"
                >
                  Düzenle
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
