/* eslint-disable jsx-a11y/img-redundant-alt */

import { Link } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Coursel = () => {
  return (
    <div className="container-fluid px-0 mb-5">
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="w-100" src="img/1.jpg" alt="Image" />
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-lg-7 text-start">
                    <p className="fs-4 text-white animated slideInRight">
                      <strong>PARS TURKEY</strong>
                      Hoşgeldiniz
                    </p>
                    <h1 className="display-1 text-white mb-4 animated slideInRight">
                      SEKTÖR UZMANI
                    </h1>
                    <Link
                      to={"/about"}
                      className="btn btn-primary rounded-pill py-3 px-5 animated slideInRight"
                    >
                      Daha Fazlası İçin
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="w-100" src="img/2.jpg" alt="Image" />
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-lg-7 text-end">
                    <p className="fs-4 text-white animated slideInRight">
                      <strong>PARS TURKEY</strong>
                    </p>
                    <h1 className="display-1 text-white mb-5 animated slideInLeft">
                      KALİTE VE GÜVEN
                    </h1>
                    <Link
                      to={"/about"}
                      className="btn btn-primary rounded-pill py-3 px-5 animated slideInRight"
                    >
                      Daha Fazlası İçin
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="w-100" src="img/3.jpg" alt="Image" />
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-lg-7 text-start">
                    <p className="fs-4 text-white animated slideInRight">
                      <strong>PARS TURKEY</strong>
                      Hoşgeldiniz
                    </p>
                    <h1 className="display-1 text-white mb-4 animated slideInRight">
                      SEKTÖR UZMANI
                    </h1>
                    <Link
                      to={"/about"}
                      className="btn btn-primary rounded-pill py-3 px-5 animated slideInRight"
                    >
                      Daha Fazlası İçin
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Coursel;
